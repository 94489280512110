import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { searchSelector, categorySelector, floorSelector, mediaSelector, shopSelector } from '~/store/selector';

import { ShopBanner, ShopTitle, Tab, Section, ContactItem, Img, Gallery } from '~/components';

import searchAction from '~/store/actions/Search';

import GetDirectionButton from '../GetDirectionButton';

import { useTranslation, useRouter, useModal, useLightbox, useTheme, useAction } from '~/hooks';
import { isEmpty } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Shop Drawer component
 * ----------------------------------------------------------------------------
 *
 * @returns
 */
function ShopDetail ({ showBanner, buttonTitle }) {
  const actions = useAction(searchAction);
  const { theme } = useTheme();
  const { push } = useRouter();
  const {
    search,
    getCategoryById,
    getFloorById,
  } = useSelector(createStructuredSelector({
    search: searchSelector.entire,
    getCategoryById: categorySelector.getCategoryById,
    getFloorById: floorSelector.getFloorById,
    getMediaById: mediaSelector.getMediaById,
    shopsForSearching: shopSelector.shopsForSearching,
    getShopById: shopSelector.getShopById,
  }));

  const { translate } = useTranslation();
  const { getQueryString, setQueryString } = useRouter();
  const { onChangeLightBox } = useLightbox();

  const { originShop, destinationShop } = search;
  const modal = useModal();

  const handleGetDirection = () => {
    console.log('handleGetDirection', { search, originShop, destinationShop });
    if (originShop.node && destinationShop.node) {
      actions.setIsAbleToGetDirection(true);
      const regexPath = '/:lang/:projectId/:origin/:destination/wayfinder';
      push({
        regexPath,
      });
      return;
    }

    if (destinationShop.nodeExist && destinationShop.unitExist) {
      modal.open('get-direction-modal');
    } else {
      modal.open('error-modal');
    }
  };

  /**
   * Render gallery tab content
   * @returns {JSX.Element}
   */
  const renderGallery = () => (
    <Gallery
      items={isEmpty(destinationShop.gallery) ? [] : destinationShop.gallery}
      title={translate(destinationShop.name)}
      onClick={onChangeLightBox}
      renderEmpty={() => (
        <Section horizontal vertical={false}>
          <p className="is-size-6 has-text-centered">{translate('noGallery')}</p>
        </Section>
      )}
    />
  );

  /**
   * Render promotion tab content
   * @returns {JSX.Element}
   */
  const renderPromotion = () => (
    <Section horizontal vertical={false}>
      <p className="is-size-6 has-text-centered">{translate('noPromotion')}</p>
    </Section>
  );

  /**
   * Render details tab content
   * @returns {JSX.Element}
   */
  const renderDetails = () => {
    const { destinationShop } = search;
    const { contact = {} } = destinationShop;
    const { website, facebook, instagram, phone } = contact;
    const hasDescription = !isEmpty(translate(destinationShop.description));
    return (
      <Section horizontal vertical={false}>
        <div className="row">
          {!isEmpty(phone) && <div className="D-6"><ContactItem icon="PhoneSquare" type="phone" text={phone} /></div>}
          {!isEmpty(facebook) && <div className="D-6"><ContactItem icon="FacebookSquare" type="link" text={facebook} /></div>}
          {!isEmpty(instagram) && <div className="D-6"><ContactItem icon="Instagram" type="link" text={instagram} /></div>}
          {!isEmpty(website) && <div className="D-6"><ContactItem icon="Globe" type="link" text={website} /></div>}
        </div>
        {hasDescription && (
          <>
            <br />
            <div className="row">
              <div className="D-12">
                <ContactItem
                  icon="ClipboardList"
                  renderProps={() => <p className="is-size-6">{translate(destinationShop.description)}</p>}
                />
              </div>
            </div>
          </>
        )}
      </Section>
    );
  };

  /**
   * Render shop detail
   */
  const renderShopDetail = () => {
    const { destinationShop } = search;

    const shopCategories = (destinationShop && destinationShop.categories) || [];
    const tab = getQueryString('tab', 'details');
    const category = getCategoryById(shopCategories[0]);
    const floor = getFloorById(destinationShop.floor);

    return (
      <Wrapper theme={theme} className="shop-detail">
        {/* {showBanner && <ShopBanner renderAs="div"><Img src={destinationShop.logo} alt="" /></ShopBanner>} */}
        {showBanner && (
          <div className="shop-banner">
            <div className="shop-banner-name-container-shopdetail">
              <Img className="shop-banner-shop-detail-img" src={destinationShop.logo} alt="" />
            </div>
          </div>
        )}
        <div className="shop-detail-header">
          <div className="head-content">
            <div className="left">
              <ShopTitle
                title={translate(destinationShop.name)}
                subTitle={translate(category ? category.title : 'noCategory')}
                floor={translate(floor ? floor.fields.name : '')}
                unitNo={destinationShop.displayUnit || ''}
              />
            </div>
            <div className="right">
              <GetDirectionButton title={buttonTitle} onClick={handleGetDirection} />
            </div>
          </div>
        </div>
        <Tab defaultActiveKey={tab} onChangeTab={nextTab => setQueryString('tab', nextTab)}>
          <Tab.TabPane title={translate('details')} key="details">
            <Tab.Content>
              {renderDetails()}
            </Tab.Content>
          </Tab.TabPane>
          <Tab.TabPane title={translate('gallery')} key="gallery">
            <Tab.Content>
              {renderGallery()}
            </Tab.Content>
          </Tab.TabPane>
          <Tab.TabPane title={translate('promotion')} key="promotion">
            <Tab.Content>
              {renderPromotion()}
            </Tab.Content>
          </Tab.TabPane>
        </Tab>
      </Wrapper>
    );
  };

  return renderShopDetail();
}
ShopDetail.propTypes = {
  showBanner: PropTypes.boolean,
  buttonTitle: PropTypes.string,
};

ShopDetail.defaultProps = {
  showBanner: true,
  buttonTitle: '',
};

const Wrapper = styled.div`
.shop-detail {
  &-header {
    border-color: ${({ theme }) => theme.colorHelper.greyLighter};
  }
}
`;

export default ShopDetail;
