import React, { useEffect, useState } from "react";
// import '~/css/promenade-style.css'

export default () => {
  return (
    <>
        <header id="masthead" className="site-header">
            <div className="site-branding container-fluid">
                <div className="home-icon">
                    <a href="https://www.thepromenade.co.th">
                        <img src="https://theprom.barn364.com/wp-content/uploads/2021/11/BAG22HEADER_NEW.png" />
                    </a>
                </div>
                <div className="row">
                    <div className="col-md-5 col-sm-6 col-xs-12 d-none d-lg-block">
                        <nav id="site-navigation" className="main-navigation d-none d-lg-block">
                            <div className="menu-menu-left-container">
                                <ul id="menu-left" className="menu">
                                    <li id="menu-item-237" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-237"><a href="https://www.thepromenade.co.th/about-us/">About</a></li>
                                    <li id="menu-item-46" className="d-none menu-item menu-item-type-custom menu-item-object-custom menu-item-46"><a href="https://www.thepromenade.co.th/about-us/">About</a></li>
                                    <li id="menu-item-47" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-47"><a href="https://www.thepromenade.co.th/events-promotions">Events and Promotions</a>
                                        <ul className="sub-menu">
                                            <li id="menu-item-48" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-48"><a href="https://www.thepromenade.co.th/events">Events</a></li>
                                            <li id="menu-item-49" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-49"><a href="https://www.thepromenade.co.th/promotion">Promotions</a></li>
                                            <li id="menu-item-50" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50"><a href="https://www.thepromenade.co.th/co-promotion">Co-Promotions</a></li>
                                            <li id="menu-item-134" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-134"><a href="https://www.thepromenade.co.th/promotions/tourist-privilege-card/">TOURIST PRIVILEGES</a></li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-51" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-51"><a href="https://www.thepromenade.co.th/shop">Shop</a></li>
                                </ul>
                            </div>					
                        </nav>
                    </div>
                    <div className="col-md-2 col-4">
                        <a href="https://www.thepromenade.co.th" className="custom-logo-link" rel="home" aria-current="page">
                            <img width="400" height="155" src="https://theprom.barn364.com/wp-content/uploads/2021/10/logo_thepromenade-2.png" className="custom-logo" alt="THE PROMENADE" />
                        </a>
                    </div>
                    <div className="col-md-5 col-sm-6 col-xs-12">
                        <nav id="site-navigation" className="main-navigation d-none d-lg-block">
                            <div className="menu-menu-right-container">
                                <ul id="menu-right" className="menu">
                                    <li id="menu-item-52" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-52"><a target="_blank" rel="noopener" href="https://directory.fashionisland.co.th/en/thepromenade/explore">Directory</a></li>
                                    <li id="menu-item-53" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-53"><a href="https://www.thepromenade.co.th/service-customer">Services</a>
                                        <ul className="sub-menu">
                                            <li id="menu-item-110" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-110"><a href="https://www.thepromenade.co.th/service-customer">Services for Customer</a></li>
                                            <li id="menu-item-111" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-111"><a href="https://www.thepromenade.co.th/service-tenants">Services for Tenants</a></li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-54" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-54"><a href="https://www.thepromenade.co.th/contact-us">Contact</a>
                                        <ul className="sub-menu">
                                            <li id="menu-item-1253" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1253"><a target="_blank" rel="noopener" href="https://www.fashionisland.co.th/career/">Career</a></li>
                                            <li id="menu-item-1254" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1254"><a href="/privacy-policy">PRIVACY POLICY</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>					
                        </nav>
                    </div>
                </div>
                <div className="lang-container">
                    {/* <div className="wrp-search-btn">
                        <button className="btn" id="toggle-form" type="submit"><i className="far fa-search"></i></button>
                    </div> */}
                    <ul className="polylang-wpr">	
                        <li className="lang-item lang-item-5 lang-item-en lang-item-first"><a lang="en-US" hreflang="en-US" href="#">EN</a></li>
                        <li className="lang-item lang-item-2 lang-item-th current-lang"><a lang="th" hreflang="th" href="#">TH</a></li>
                    </ul>
                </div>
                <a data-toggle="collapse" data-target="#navbarMenu" className="btn lg-menu btn-toggle-menu collapsed" aria-expanded="false"><i className="fas fa-bars"></i></a>
            </div>
            <div className="wrp-search-form">
                <form className="search-form" method="get" action="https://www.thepromenade.co.th/">
                    <button className="btn" type="submit"><i className="far fa-search"></i></button>
                    <input type="text" name="s" required="" />
                </form>
                <div className="link-search">
                    <a href="https://www.thepromenade.co.th/events/">อีเว้นท์</a>
                    <a href="https://www.thepromenade.co.th/promotion">โปรโมชั่น</a>
                    <a href="https://www.thepromenade.co.th/shop">ร้านค้า</a>
                </div>
            </div>
        </header>
        <div id="navbarMenu" className="start-load toggleMenu collapse">
            <button type="button" data-toggle="collapse" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="true" className="navbar-toggler"><span className="navbar-toggler-bar"><i className="fas fa-times"></i></span> <span className="close-button"></span></button>
            <div className="header-link">
                <ul className="polylang-wpr"></ul>
            </div>
            <div className="row">
                <div className="col-12"> 
                    <a href="https://www.thepromenade.co.th" className="custom-logo-link" rel="home" aria-current="page">
                        <img width="400" height="155" src="https://theprom.barn364.com/wp-content/uploads/2021/10/logo_thepromenade-2.png" className="custom-logo" alt="THE PROMENADE" />
                    </a>
                </div>
            </div>
            <div className="mobile-menu">
                <div className="menu-mobile-menu-container">
                    <ul id="primary-menu" className="menu">
                        <li id="menu-item-238" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-238"><a href="https://www.thepromenade.co.th/about-us/">About</a></li>
                        <li id="menu-item-62" data-target="#eventMenu" aria-expanded="false" data-toggle="collapse" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-62"><a href="#">Events and Promotions</a>
                            <ul id="eventMenu" className="sub-menu collapse">
                                <li id="menu-item-946" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-946"><a href="https://www.thepromenade.co.th/events-promotions">Events and Promotions</a></li>
                                <li id="menu-item-63" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-63"><a href="https://www.thepromenade.co.th/events">Events</a></li>
                                <li id="menu-item-64" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-64"><a href="https://www.thepromenade.co.th/promotion">Promotions</a></li>
                                <li id="menu-item-65" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-65"><a href="https://www.thepromenade.co.th/co-promotion">Co-Promotions</a></li>
                                <li id="menu-item-133" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-133"><a href="https://www.thepromenade.co.th/promotions/tourist-privilege-card/">Tourist Privileges</a></li>
                            </ul>
                        </li>
                        <li id="menu-item-66" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-66"><a href="https://www.thepromenade.co.th/shop">Shop</a></li>
                        <li id="menu-item-67" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-67"><a target="_blank" rel="noopener" href="https://directory.fashionisland.co.th/en/thepromenade/explore">Directory</a></li>
                        <li id="menu-item-68" data-target="#serviceMenu" aria-expanded="false" data-toggle="collapse" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-68"><a href="#">Services</a>
                            <ul id="serviceMenu" className="sub-menu collapse">
                                <li id="menu-item-112" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-112"><a href="https://www.thepromenade.co.th/service-customer">Services for Customer</a></li>
                                <li id="menu-item-113" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-113"><a href="https://www.thepromenade.co.th/service-tenants">Services for Tenants</a></li>
                            </ul>
                        </li>
                        <li id="menu-item-69" data-target="#contactMenu" aria-expanded="false" data-toggle="collapse" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-69"><a href="#">Contact</a>
                            <ul id="contactMenu" className="sub-menu collapse">
                                <li id="menu-item-1263" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1263"><a href="https://www.thepromenade.co.th/contact-us">Contact</a></li>
                                <li id="menu-item-1256" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1256"><a target="_blank" rel="noopener" href="https://www.fashionisland.co.th/career/">Career</a></li>
                                <li id="menu-item-1255" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1255"><a href="https://www.thepromenade.co.th/privacy-policy">PRIVACY POLICY</a></li>
                            </ul>
                        </li>
                        <li id="menu-item-120" className="lang-item d-none menu-item menu-item-type-custom menu-item-object-custom menu-item-120"><a href="#">TH</a></li>
                        <li id="menu-item-121" className="lang-item d-none menu-item menu-item-type-custom menu-item-object-custom menu-item-121"><a href="#">EN</a></li>
                    </ul>
                </div>
                <div className="lang-container">
                    <ul className="polylang-wpr">	
                        <li className="lang-item lang-item-5 lang-item-en lang-item-first"><a lang="en-US" hreflang="en-US" href="#">EN</a></li>
                        <li className="lang-item lang-item-2 lang-item-th current-lang"><a lang="th" hreflang="th" href="#">TH</a></li>
                    </ul>
                </div>
            </div>	
        </div>
    </>
  );
};
