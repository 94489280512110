import React from 'react';
// import '~/css/promenade-style.css'

export default () => (
  <>
    <footer id="colophon" className="site-footer">		
		<div className="container menu-footer">
			<div className="row">
				<div className="col-lg-9">
					<div className="row">

						<div className="col-lg-3">
							<div className="d-none d-lg-block">
								<div className="menu-footer-container">
                                    <div className="menu-footer-1-container">
                                        <ul id="menu-footer-1" className="menu">
                                            <li id="menu-item-70" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-70"><a href="https://www.thepromenade.co.th/about-us">ABOUT</a></li>
                                            <li id="menu-item-71" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-71"><a href="https://www.thepromenade.co.th/shop">SHOP</a></li>
                                            <li id="menu-item-440" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-440"><a href="https://www.thepromenade.co.th/service-customer">SERVICES</a></li>
                                            <li id="menu-item-441" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-441"><a href="https://www.thepromenade.co.th/privacy-policy">PRIVACY POLICY</a></li>
                                        </ul>
                                    </div>								
                                </div>
						    </div>
							<div className="d-block d-lg-none">
								<div className="row">
									<div className="col-5">
										<div className="menu-footer-container">
											<div className="menu-footer-1-container">
                                                <ul id="menu-footer-1" className="menu">
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-70"><a href="https://www.thepromenade.co.th/about-us">ABOUT</a></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-71"><a href="https://www.thepromenade.co.th/shop">SHOP</a></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-440"><a href="https://www.thepromenade.co.th/service-customer">SERVICES</a></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-441"><a href="https://www.thepromenade.co.th/privacy-policy">PRIVACY POLICY</a></li>
                                                </ul>
                                            </div>											
										</div>
									</div>
									<div className="col-7">
										<div className="menu-footer-container">
											<div className="menu-footer-2-container">
                                                <ul id="menu-footer-2" className="menu">
                                                    <li id="menu-item-443" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-443"><a href="https://www.thepromenade.co.th/events-promotions">EVENTS &#038; PROMOTIONS</a></li>
                                                    <li id="menu-item-444" className="d-none menu-item menu-item-type-custom menu-item-object-custom menu-item-444"><a href="#">DIRECTORY</a></li>
                                                    <li id="menu-item-445" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-445"><a target="_blank" rel="noopener" href="https://www.fashionisland.co.th/career/">CAREER</a></li>
                                                </ul>
                                            </div>
										</div>
									</div>
								</div>
							</div>

						</div>

						<div className="col-lg-4 over-width d-none d-lg-block">
							<div className="menu-footer-container">
                                <div className="menu-footer-2-container">
                                    <ul id="menu-footer-2" className="menu">
                                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-443"><a href="https://www.thepromenade.co.th/events-promotions">EVENTS &#038; PROMOTIONS</a></li>
                                        <li className="d-none menu-item menu-item-type-custom menu-item-object-custom menu-item-444"><a href="https://directory.fashionisland.co.th/en/thepromenade/explore">DIRECTORY</a></li>
                                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-445"><a target="_blank" rel="noopener" href="https://www.fashionisland.co.th/career/">CAREER</a></li>
                                    </ul>
                                </div>							
                            </div>
						</div>
						<div className="col-lg-5 big-ft-menu">
							<div className="menu-footer-container">
                                <div className="menu-footer-3-container">
                                    <ul id="menu-footer-3" className="menu">
                                        <li id="menu-item-446" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-446"><a href="https://www.thepromenade.co.th/contact-us">CONTACT US</a></li>
                                    </ul>
                                </div>							
                            </div>
							<p>บริษัท สยาม รีเทล ดีเวลล็อปเม้นท์ จำกัด<br />
                            587,589, 589/7-9 ถนนรามอินทรา แขวงคันนายาว<br />
                            เขตคันนายาว กรุงเทพมหานคร 10230</p>
						</div>
					</div>
				</div>

				<div className="col-lg-3 big-ft-menu">
					<div className="social">
						<a target="_blank" href="https://www.facebook.com/FashionislandPromenade">
							<img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/icon-facebook.png" className="" alt="" />
                        </a>
                        <a target="_blank" href="https://twitter.com/fashionislandTH">
							<img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/icon-twitter.png" className="" alt="" />
                        </a>						
                        <a target="_blank" href="https://www.youtube.com/user/FashionIslandBKK">
							<img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/icon-youtube.png" className="" alt="" />
                        </a>						
                        <a target="_blank" href="https://www.instagram.com/fashionislandth/">
							<img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/icon-instagram.png" className="" alt=""/>
                        </a>						
                        <a target="_blank" href="https://page.line.me/fashionpromenade?openQrModal=true">
							<img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/icon-line.png" className="" alt=""/>
						</a>
                    </div>
					<div className="call-footer">
						<p><a href="tel:+6629475000">CALL US : +662-947-5000</a><br /></p>
						<p><a style={{ pointerEvents: 'none' }}>OPEN DAILY : 10.00-22.00 HRS</a></p>
					</div>

				</div>
			</div>
		</div>
		<div className="container address d-none">
			<div className="row">
				<div className="col-12">
                    <p style={{ textAlign: 'center' }}><p>บริษัท สยาม รีเทล ดีเวลล็อปเม้นท์ จำกัด<br />
                    587,589, 589/7-9 ถนนรามอินทรา แขวงคันนายาว<br />
                    เขตคันนายาว กรุงเทพมหานคร 10230</p>
                    </p>
				</div>
			</div>
		</div>
		<div className="bg-footer" style={{ backgroundImage: 'url(https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/footer_bg.png)' }}></div>
				<div className="info" style={{ textAlign: 'center' }}>
			<p>© 2021 THE PROMENADE MALL. ALL RIGHTS RESERVED.</p>
		</div>
	</footer>
    <div className="mobile-sticky d-block d-md-none">
		<div className="row center">
			<div className="col-3">
				<a href="https://www.google.com/maps/dir/?api=1&destination=The+Promenade+Lifestyle+Mall" target="_blank">
				<img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/footer_map_icon.jpg"/><i className="fas fa-map-marked-alt d-none"></i><span>Location</span>
				</a>
			</div>
			<div className="col-3">
				<a href="https://directory.fashionisland.co.th/en/thepromenade/explore" target="_blank">
					<img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/escalator.jpg"/><span>Directory</span>
				</a>
			</div>
			<div className="col-3 d-none">
				<a href="#">
					<img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/parking_icon.jpg"/><span>Parking</span>
				</a>
			</div>
			<div className="col-3">
				<a href="tel:+6629475000">
					<img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/footer_tel_icon.jpg"/><span>Contact</span>
				</a>
			</div>
		</div>
	</div>
	<div className="social-sticky">
		<ul className="social-main">
            <li>
                <p>FOLLOW<br /> ON US</p>
                <a href="https://www.facebook.com/FashionislandPromenade" target="_blank">
                    <i className="fab fa-facebook-f"></i>
                </a>
            </li>
            <li>
                <a href="tel:+6629475000" target="_blank">
                    <img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/tel_icon_left.png" width="40" className="icon_nhv" />
                    <img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/tel_icon_left_pink.png" width="40" className="icon_hv" />
                </a>
            </li>
            <li>
                <a href="https://page.line.me/fashionpromenade?openQrModal=true" target="_blank">
                    <img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/line_icon_left.png" width="40" className="icon_nhv" />
                    <img src="https://theprom.barn364.com/wp-content/themes/betimes-cms-master/img/line_icon_left_pink.png" width="40" className="icon_hv" />
                </a>
            </li>
		</ul>
	</div>
  </>
);
