import compact from 'lodash.compact';


/**
 * Get env
 *
 * @param {string} name
 * @param {string} [prefix = 'REACT_APP']
 */
function get (name, defaultValue, prefix = 'REACT_APP') {
  const target = compact([ prefix, name ]).join('_');
  return process.env[target] ?? defaultValue;
}

export default {
  PUBLIC_URL: get('PUBLIC_URL', '/', null),
  NODE_ENV: get('NODE_ENV', 'development', null),
  DC_URL: get('DC_URL'),
  FALLBACK_LANGUAGE: get('FALLBACK_LANGUAGE'),
  ZENTRY_URL: get('ZENTRY_URL'),
  BASE_URL: get('BASE_URL'),
  MOCK_PATH: get('MOCK_PATH', 'mock'),
  API_VERSION: get('API_VERSION')
};
