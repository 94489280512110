import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useRouter from 'use-react-router';

import { bindingClassName, getFile } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Card image component
 * ----------------------------------------------------------------------------
 *
 * @returns
 */
function Img ({ src, alt }) {
  const [ img, setImg ] = useState(src);
  const { match: { params: { projectId } } } = useRouter();

  const handleOnError = () => {
    setImg(getFile(`/images/${projectId}/shop-no-logo.png`));
  };

  useEffect(() => {
    if (!src) setImg(getFile(`/images/${projectId}/shop-no-logo.png`));
  }, [src])

  return (
    <div className={bindingClassName('app-card-image')}>
      <img src={img} alt={alt} onError={handleOnError} />
    </div>
  );
}

Img.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

Img.defaultProps = {
  src: '',
  alt: '',
};

export default Img;
