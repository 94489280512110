import { fetcher } from '~/utils';
import config from '~/lib/config';

/**
 * Content apis
 */
export default {
  /**
   * Get content by provided project id
   * @param {String} projectId
   */
  async getContentByProjectId(baseUrl, apiKey, projectId, onDownloadProgress) {
    // get baseUrl
    // const { BASE_URL, API_VERSION } = config;
    // loading credential for target project
    // const credential = await import(`../assets/keys/${projectId}.json`); // import api key for specific project
    // const apiKey = credential.apiKey; 
    return fetcher({
      useMock: false,
      method: 'get',
      jsonMock: `/json/${projectId}/project.json`,
      origin: baseUrl,
      headers: {
        key: apiKey,
      },
      url: `/contents`,
      // url: `/${projectId}/json/project.json`, DC URL
      onDownloadProgress,
    });
  },
  /**
   * Get mockup floor icon
   */
  getFloorIcon (projectId) {
    return fetcher({
      useMock: true,
      jsonMock: `/floor-icon/${projectId}/floor-icon.json`,
    });
  },
  /**
   * Get movement media
   */
  getMovementMedia () {
    return fetcher({
      useMock: true,
      jsonMock: '/movement.json',
    });
  },
};
