import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTransition, animated } from 'react-spring';

import { useTheme } from '~/hooks';

/**
 * ----------------------------------------------------------------------------
 * Layer component
 * ----------------------------------------------------------------------------
 *
 * @param {{ units: array, highLightUnits }} props
 * @param {Array} props.units
 * @param {Array} props.highLightUnits
 */
const Layer = ({ units, activeUnits, onClickUnit }) => {
  const { theme } = useTheme();

  const renderFacility = (unit) => {
    const {
      x,
      y,
      icon,
      id,
      width,
      height,
    } = unit;

    return (
      <g key={`${x}${y}`}>
        <image
          id={id}
          xlinkHref={icon}
          x={x}
          y={y}
          height={height || 0}
          width={width || 0}
          style={{
            pointerEvents: 'none',
            transformOrigin: `${((width) / 2) || 0}px ${((height) / 2) || 0}px`,
          }}
        />
      </g>
    );
  };

  const onClickUnitTest = (id, unit) => {
    console.log('onclick on onClickUnitTest >>>> ', id, unit);
  };

  const onClickUnitTouch = (id, unit) => {
    console.log('onclick on onClickUnitTouch >>>> ', id, unit);
  };

  const renderShop = (unit) => {
    // console.log('renderShop', unit);
    const {
      id = '',
      type,
      'stroke-linecap': strokeLinecap,
      'stroke-miterlimit': strokeMiterlimit,
      'stroke-width': strokeWidth,
      'stroke-linejoin': strokeLinejoin,
      hasShop,
      ...rest
    } = unit;
    const SvgType = type;
    const isActive = activeUnits.find(activeUnit => {
      const regex = new RegExp(id, 'i')
      const unitNo = activeUnit.unitNo ? activeUnit.unitNo : ''
      return unitNo === id.toLowerCase()
    });
    return (
      <SvgType
        id={id.toLowerCase()}
        {...rest}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        strokeLinejoin={strokeLinejoin}
        fill={isActive ? theme.wayfinding.hiLightUnitColor || theme.colorHelper.secondary : 'transparent'}
        stroke={isActive ? 'red' : 'transparent'}
        className={isActive ? 'fade-in-out' : ''}
        onClick={() => onClickUnit(id, unit)} // onClickUnit(id, unit)
      />
    );
  };

  /**
   *
   * <SvgType
        id={id}
        {...rest}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        strokeLinejoin={strokeLinejoin}
        fill={isActive ? theme.wayfinding.hiLightUnitColor || theme.colorHelper.secondary : 'transparent'}
        stroke={isActive ? 'red' : 'transparent'}
        className={isActive ? 'fade-in-out' : ''}
        onClick={() => onClickUnit(id, unit)}
      />
   */

  const renderUnit = (unit) => {
    const { id, category } = unit;

    return (
      <g key={id || Math.floor(Math.random() * 101)}>
        {category === 'facility' && renderFacility(unit)}
        {category === 'shop' && renderShop(unit)}
      </g>
    );
  };

  return (
    <Wrapper>
      {units.map(renderUnit)}
    </Wrapper>
  );
};

Layer.propTypes = {
  units: PropTypes.arrayOf(PropTypes.shape()),
  activeUnits: PropTypes.arrayOf(PropTypes.shape()),
  onClickUnit: PropTypes.func,
};

Layer.defaultProps = {
  units: [],
  activeUnits: [],
  onClickUnit: () => {},
};

const Wrapper = styled.g``;

export default Layer;
