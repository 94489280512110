import contentService from "~/apis/Content";
import entity from "~/store/modules/Entity";
import { actionFetcher, intersection, isEmpty } from "~/utils";

const getProjectProperties = (projectId) => {
  const property = {
    defaultFloors: {
      fashionisland: 'fashionisland-1',
      thepromenade: 'thepromenade-1',
      'terminal21-asok': 'terminal21-korat-1f',
      'terminal21-pattaya': 'terminal21-pattaya-1f',
      'terminal21-korat': 'terminal21-korat-1f',
      'terminal21-rama3': 'terminal21-rama3-1f',
    },
  };
  switch (projectId) {
    default:
    case 'fashionisland':
      property.apiBaseUrl = 'https://api-directory.fashionisland.co.th/api/v1';
      property.apiKey =
        'd08e709c2ec027e7637e2b3be53465100c44a572e79aaa8a40a62990121e68e0';
      property.projectId = 1;
      property.shouldFilterProjectCategory = false;
      property.disableCategories = [];
      break;
    case 'thepromenade':
      property.apiBaseUrl = 'https://api-directory.fashionisland.co.th/api/v1';
      property.apiKey =
        'a92e1e62fe228f617a75ca640c295ad78b2c09470bd65669c1d4dc81040c683f';
      property.projectId = 2;
      property.shouldFilterProjectCategory = false;
      property.disableCategories = [];
      break;
    case 'terminal21-asok':
      property.apiBaseUrl = 'https://api-directory.terminal21.co.th/api/v1';
      property.apiKey =
        'c771431b39b6852f946dc827b3b2cefcdefa4dcbd1b0119552be44835ee9f18e';
      property.projectId = 1;
      property.shouldFilterProjectCategory = true;
      property.disableCategories = [199, 299];
      break;
    case 'terminal21-pattaya':
      property.apiBaseUrl = 'https://api-directory.terminal21.co.th/api/v1';
      property.apiKey =
        '96d725e8874413241a7be35d90853df39a5bd03ff79c67d48627d1757bbb3fa0';
      property.projectId = 2;
      property.shouldFilterProjectCategory = true;
      property.disableCategories = [199, 299];
      break;
    case 'terminal21-korat':
      property.apiBaseUrl = 'https://api-directory.terminal21.co.th/api/v1';
      property.apiKey =
        'fd4a1c869c5783c6d4b0d2a35b37359ae8594aa0d30c61a44b9a71163e4acf05';
      property.projectId = 3;
      property.shouldFilterProjectCategory = true;
      property.disableCategories = [199, 299];
      break;
    case 'terminal21-rama3':
      property.apiBaseUrl = 'https://api-directory.terminal21.co.th/api/v1';
      property.apiKey =
        '31ds784atr5fpevgo62344gfsgv79lwwbrpxbgu5q8379r81gd0c2o01328aw0gd';
      property.projectId = 4;
      property.shouldFilterProjectCategory = true;
      property.disableCategories = [311, 399];
      break;
  }
  return property;
};

const verifyIsOmbresJson = (projectJson) => {
  const firstShop = projectJson.shops[Object.keys(projectJson.shops)[0]];
  return firstShop.name !== undefined && firstShop.title === undefined;
};
const verifyIsPhloxJson = (projectJson) => {
  const firstShop = projectJson.shops[Object.keys(projectJson.shops)[0]];
  return firstShop.title !== undefined;
};
// const toUgdShop = (ombresShop) => {
//   return {
//     id: '',
//     shopId: '',
//     title: {
//       th: 'จีน จีน',
//       en: 'JEANJEAN'
//     },
//     description: {
//       th: 'จีน จีน,อุปกรณ์เดินป่า,เสื้อผ้า หมวกลายทหาร,แว่น',
//       en: ''
//     },
//     slug: 'jeanjean-5c9',
//     keywords: [
//       'จีน',
//       'อุปกรณ์',
//       'เดิน',
//       'ป่า',
//       'เสื้อ',
//       'ผ้า',
//       'หมวก',
//       'ลาย',
//       'ทหาร',
//       'แว่น',
//       'JEANJEAN'
//     ],
//     categories: [
//       '62',
//       '68'
//     ],
//     floorId: '103',
//     floor: 'fashionisland-3',
//     contact: {
//       email: '',
//       website: '',
//       facebook: '',
//       instagram: '',
//       phone: ''
//     },
//     contract: [
//       '2019-01-01',
//       '2070-03-16'
//     ],
//     ownerId: null,
//     projectId: 1,
//     entityTypeId: 1,
//     gallery: [],
//     logo: '',
//     unit: 'DC09',
//     node: '',
//     status: 'published'
//   }
// };

export default {
  /**
   * Get content by provided project id
   *
   * @param {String} projectId
   * @return {Function}
   */
  getContentByProjectId(projectId, onDownloadProgress) {
    return actionFetcher({
      loadingMessage: "Fetching content from dc",
      successMessage: "Success",
      async callAction(dispatch) {
        try {
          const projectProperties = getProjectProperties(projectId);
          const {
            apiBaseUrl,
            apiKey,
            projectId: systemProjectId,
            defaultFloors: buildingDefaultFloors,
            disableCategories,
            shouldFilterProjectCategory
          } = projectProperties;
          console.log('projectProperties', projectProperties);
          const contentJson = await contentService.getContentByProjectId(
            apiBaseUrl,
            apiKey,
            projectId,
            onDownloadProgress,
          );
          let data = contentJson.data;
          if (!data) {
            data = contentJson;
          }
          const rawData = { ...data };

          console.log('resources', { data, rawData });

          const isOmbresJson = verifyIsOmbresJson(data);
          const isPhloxJson = verifyIsPhloxJson(data);

          let projectUnits = {};

          console.debug('checking project.json version', {
            data,
            isOmbresJson,
            isPhloxJson,
          });

          // required iam_projects
          if (!data.iam_projects) {
            data.iam_projects = {
              client: true,
            };
          }
          // required buildings with defaultFloor
          if (data.buildings) {
            if (Array.isArray(data.buildings)) {
              data.building = data.buildings.reduce((acc, building) => {
                if (building.projectId !== systemProjectId) return acc
                return {
                  ...acc,
                  [building.slug]: {
                    ...building,
                    name: building.title,
                    order: building.id,
                    defaultFloor: buildingDefaultFloors[building.slug],
                  },
                };
              }, {});
            } else {
              data.building = data.buildings;
            }
            delete data['buildings'];
          }
          // required HashMap<> floors
          if (Array.isArray(data.floors)) {
            let medias = {};
            data.floors = data.floors.reduce(
              (acc, { id, slug, title, order, units, image, meta, buildingId }) => {
                if (!Object.keys(data.building).find((buildingKey) => data.building[buildingKey].id === buildingId)) return acc;
                medias[slug] = {
                  url: image,
                };
                const reFormattedUnits = units.map((unit) => {
                  const unitSlug = unit.slug.toLowerCase();
                  const unitData = { ...unit, ...unit.data };
                  if (!projectUnits[unitSlug]) {
                    projectUnits[unitSlug] = [unitData];
                  } else {
                    projectUnits[unitSlug] = [
                      ...projectUnits[unitSlug],
                      unitData,
                    ];
                  }
                  return unitData;
                })
                return {
                  ...acc,
                  [`${slug}`]: {
                    id,
                    order,
                    fields: {
                      svgHeight: meta.svgHeight,
                      svgWidth: meta.svgWidth,
                      building: 'all',
                      floorplan: slug,
                      status: 'Published',
                      detail: {
                        en: '',
                        th: '',
                      },
                      name: title,
                    },
                    slug,
                    units: reFormattedUnits,
                    elements: [],
                  },
                };
              },
              {},
            );
            data.medias = medias;
          }
          // console.debug('projectUnits', projectUnits);
          // required HashMap<> nodes
          if (Array.isArray(data.nodes)) {
            data.nodes = data.nodes.reduce(
              (acc, { id, title, floorId, x, y, neighbors }) => {
                if (
                  !Object.keys(data.floors).find(
                    (floorKey) => data.floors[floorKey].id === floorId,
                  )
                ) return acc;
                const floorSlug = Object.keys(data.floors).find(
                  (floorKey) => data.floors[floorKey].id === floorId,
                );
                const differentFloorNeighborNodes = neighbors.filter(
                  (neighbor) => neighbor.floorId !== floorId,
                );
                const isLiftNode = differentFloorNeighborNodes.length > 2;
                const isEscalatorNode = differentFloorNeighborNodes.length > 0 && differentFloorNeighborNodes.length <= 2;

                return {
                  ...acc,
                  [`${title}`]: {
                    id: title,
                    _id: id,
                    floorId: floorSlug,
                    neighbor: neighbors.map((neighbor) => neighbor.title),
                    x,
                    y,
                    isLiftNode,
                    isEscalatorNode,
                  },
                };
              },
              {},
            );
          }
          // required HashMap<> categories
          if (Array.isArray(data.categories)) {
            data.categories = data.categories.reduce((acc, category) => {
              if (shouldFilterProjectCategory &&
                (category.projectId !== systemProjectId)) {
                return acc;
              }
              if (
                disableCategories.indexOf(category.id) !== -1
              ) {
                return acc;
              }
              return {
                ...acc,
                [category.id]: {
                  ...category,
                  name: category.title,
                },
              };
            }, {});
          }
          // required HashMap<> shops
          if (Array.isArray(data.shops)) {
            data.shops = data.shops.reduce((acc, shop, index) => {
              // console.log(
              //   `shop: ${index + 1}`,
              //   shop,
              // );
              if (shop.projectId !== systemProjectId) return acc;
              if (shop.categories.length > 0 && shop.categories[0].id) {
                shop.categories = shop.categories.map((category) => {
                  return category.id;
                });
              } else if (shop.categories.length > 0 && shop.categories[0].en) {
                shop.categories = shop.categories.map((category) => {
                  const categoryTitleEn = category.en
                  const categoryId = Object.keys(data.categories).find(categoryKey => {
                    return (
                      data.categories[categoryKey].title.en === categoryTitleEn
                    );
                  });
                  return categoryId ? parseInt(categoryId) : null
                }).filter(Boolean);
              }
              if (typeof shop.id === 'number') {
                shop.shopId = shop.id
                shop.id = shop.slug
              }
              if (shop.contract && shop.contract.length > 0 && shop.contract[0].value) {
                shop.contract = shop.contract.map((contract) => contract.value);
              }
              if (shop.unit) {
                shop.unit = typeof shop.unit === "string" ? shop.unit.toLowerCase() : shop.unit
              }
              if (shop.floor) {
                // "floorId": "100",
                // "floor": "fashionisland-b",
                const shopUnit = projectUnits[shop.unit];
                shop.floorId = shopUnit ? shopUnit[0].floorId : null;
                const shopFloorSlug = Object.keys(data.floors).find((floorSlug) => data.floors[floorSlug].id === shop.floorId)
                shop.floor = shopFloorSlug || null;
              }
              if (
                intersection(shop.categories, disableCategories).length <= 0 &&
                !isEmpty(shop.floor)
              ) {
                const shopUniqueId = shop.slug || shop.nid || shop.id || index;
                return {
                  ...acc,
                  [`${shopUniqueId}`]: {
                    ...shop,
                    name: shop.title,
                  },
                };
              }
              return acc;
            }, {});
          }
          if (isOmbresJson) {
            const { medias } = data;
            // add slug to floor
            data.floors = Object.keys(data.floors).reduce((acc, floorKey) => {
              const floor = data.floors[floorKey];
              return {
                ...acc,
                [floorKey]: {
                  ...floor,
                  slug: floor.id,
                },
              };
            }, {});
            data.categories = Object.keys(data.categories || {}).reduce(
              (acc, categoryKey) => {
                const category = data.categories[categoryKey];
                return {
                  ...acc,
                  [categoryKey]: {
                    id: categoryKey,
                    slug: categoryKey,
                    name: category.name,
                    title: category.name,
                    parent: category.parent || null,
                    parentId: category.parent || null,
                    projectId: 1,
                  },
                };
              },
              {},
            );
            // transform category and subcategory to categories = [category, subcategory]
            data.shops = Object.keys(data.shops).reduce((acc, index) => {
              const shop = data.shops[index];
              const shopUniqueId = shop.nid || shop.id || shop.slug || index;
              const validShop = !isEmpty(shop.floor);
              if (!validShop) {
                console.log(shop.name.en, 'is invalid.', shop);
                return acc;
              }
              const logo = (medias[shop.logo] && medias[shop.logo].url) || '';
              const unit =
                (shop.units && shop.units[0] && shop.units[0].unitNo) || '';
              const galleries = shop.gallery
                ? shop.gallery.map(
                    (mediaKey) =>
                      (medias[mediaKey] && medias[mediaKey].url) || '',
                  )
                : [];
              const categories = [];
              if (shop.category) {
                if (!isNaN(Number(shop.category))) {
                  categories.push(Number(shop.category));
                } else {
                  categories.push(shop.category);
                }
              }
              if (shop.subcategory) {
                if (!isNaN(Number(shop.subcategory))) {
                  categories.push(Number(shop.subcategory));
                } else {
                  categories.push(shop.subcategory);
                }
              }
              // console.log('shop.name.en', shop.name.en);
              const slug = shopUniqueId;
              return {
                ...acc,
                [slug]: {
                  ...shop,
                  slug,
                  title: shop.name,
                  keywords: shop.tags,
                  categories,
                  unit,
                  logo,
                  gallery: galleries,
                },
              };
            }, {});
          }


          // remove non-node / non-unit shop
          const { floors, shops, nodes } = data;

          const missingUnitShops = {};

          Object.keys(shops).map((shopKey) => {
            const shop = shops[shopKey];
            if (!shop.name.en || !shop.name.th) {
              console.log(shop.unit, 'en:', shop.name.en, 'th', shop.name.th);
            }
            const floorData = floors[shop.floor];
            if (floorData) {
              const isNodeExist = shop.node && nodes[shop.node];
              const isUnitExist = floorData.units.find(
                (unit) => {
                  const regex = new RegExp(unit.id, 'ig')
                  return regex.test(shop.unit)
                }
              )
              if (shop.status === 'draft') {
                delete shops[shopKey];
              }
              if (!isNodeExist) {
                shop.nodeExist = false;
              } else {
                shop.nodeExist = true;
              }
              if (!isUnitExist) {
                shop.unitExist = false;
              } else {
                shop.unitExist = true;
              }
              if (!isNodeExist || !isUnitExist) {
                if (missingUnitShops[shop.floor]) {
                  missingUnitShops[shop.floor] = [
                    ...missingUnitShops[shop.floor],
                    shop,
                  ];
                } else {
                  missingUnitShops[shop.floor] = [shop];
                }
                delete shops[shopKey];
              }
            }
          });

          /* eslint-disable */
          Object.keys(missingUnitShops).map((floorKey) => {
            console.log('============', floorKey, '============');
            missingUnitShops[floorKey].map((shop) => {
              console.log(
                `${shop.name.th}  ${shop.name.en} ${
                  !shop.nodeExist ? 'X node' : ''
                } ${!shop.unitExist ? 'X unit' : ''}`,
              );
            });
          });
          /* eslint-enable */

          console.log('final resources', data);

          dispatch(entity.setEntity({ payload: data }));
          return data;
        } catch (error) {
          console.error('getContentByProjectId', error);
        }
      },
    });
  },
  /**
   * Get floor icon
   *
   * @returns
   * @function
   */
  getFloorIcon(projectId) {
    return actionFetcher({
      loadingMessage: "Fetching floor icon from mockup data",
      successMessage: "Success fetching floor icon",
      async callAction(dispatch) {
        const floorIcon = await contentService.getFloorIcon(projectId);
        if (floorIcon) {
          dispatch(entity.setFloorIcon({ payload: floorIcon }));
        }
        return floorIcon;
      },
    });
  },
  /**
   * Get movement media
   *
   * @returns
   * @function
   */
  getMovementMedia() {
    return actionFetcher({
      loadingMessage: "Fetching movement media from mockup data",
      successMessage: "Success fetching movement media",
      async callAction(dispatch) {
        const movementMedia = await contentService.getMovementMedia();
        dispatch(entity.addMedia({ payload: movementMedia }));

        return movementMedia;
      },
    });
  },
};
