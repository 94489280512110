import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import useTranslation from "./useTranslation";

import { floorSelector, categorySelector } from "~/store/selector";

import { isEmpty } from "~/utils";

const useSvgPopup = (shop) => {
  const { translate } = useTranslation();
  const { getCategoryById, getFloorById } = useSelector(
    createStructuredSelector({
      getCategoryById: categorySelector.getCategoryById,
      getFloorById: floorSelector.getFloorById,
    })
  );
  const unitDOM = document.getElementById(`${shop.unit}`);
  
  if (isEmpty(unitDOM)) return {};
  const BBox = unitDOM.getBBox();
  return {
    type: "basic",
    data: BBox && {
      x: BBox.x + BBox.width / 2,
      y: BBox.y + BBox.height / 2,
      logo: shop.logo,
      title: translate(shop.name),
      subTitle:
        shop?.categories &&
        shop?.categories[0] &&
        translate(getCategoryById(shop?.categories[0])?.name),
      text: translate(getFloorById(shop?.floor)?.fields?.name),
    },
  };
};

export default useSvgPopup;
