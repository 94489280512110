import search from '~/store/modules/Search';

import { WAYFINDER_METHOD_OVERVIEW, WAYFINDER_METHOD_STEP_AND_MORE } from '~/app/variables';

/**
 * ----------------------------------------------------------------------------
 * Search actions
 * ----------------------------------------------------------------------------
 *
 * There are 2 function:
 * 1. setOriginSearchText() - set origin search text to search module on redux store
 * 2. setOriginShop() - set origin shop to search module on redux store
 * 3. setOrigin()
 * 4. setDestinationSearchText() - ser destination search text to search module on redux store
 * 5. setDestinationShop() - set destination shop to search module on redux store
 * 6. setDestination()
 * 7. setIsAbleToGetDirecion()
 */
export default {
  /**
   * Set origin search text
   *
   * @description set search text value of origin
   * @param  {String} origin
   */
  setOriginSearchText (origin) {
    return (dispatch) => {
      dispatch(search.setOriginSearchText({ payload: origin }));
    };
  },
  /**
   * Set origin shop
   *
   * @param {{}} unit
   */
  setOriginShop (unit) {
    return (dispatch) => {
      dispatch(search.setOriginShop({ payload: unit }));
    };
  },
  /**
   * Set origin search
   *
   * @param {{ originSearchText: string, originShop: {} }} payload
   */
  setOrigin (payload) {
    return (dispatch) => {
      dispatch(search.setOrigin({ payload }));
    };
  },
  /**
   * Set destination search text
   *
   * @description set search text value of destination
   * @param  {String} destination
   */
  setDestinationSearchText (destination) {
    return (dispatch) => {
      dispatch(search.setDestinationSearchText({ payload: destination }));
    };
  },
  /**
   * Set destination shop
   *
   * @param {{}} unit
   */
  setDestinationShop (unit) {
    return (dispatch) => {
      dispatch(search.setDestinationShop({ payload: unit }));
    };
  },
  /**
   * Set destination
   *
   * @param {{ destinationSearchText: string, destinationShop: {} }} payload
   */
  setDestination (payload) {
    return (dispatch) => {
      dispatch(search.setDestination({ payload }));
    };
  },
  /**
   * Set is able to use get directon feature
   *
   * @description this function tell the application should disable get direction feature or not.
   * @param {Boolean} bool
   */
  setIsAbleToGetDirection (bool) {
    return (dispatch) => {
      dispatch(search.setIsAbleToGetDirection({ payload: bool }));
    };
  },
  /**
   * Set wayFinderMethod to be 'overview'
   *
   * @description this function call action to set wayFinderMethod to be 'overview'
   * @param {Boolean} bool
   */
  setWayFinderMethodOverview () {
    return (dispatch) => {
      dispatch(search.setWayFinderMethod({ payload: WAYFINDER_METHOD_OVERVIEW }));
    };
  },
  /**
   * Set wayFinderMethod to be 'step-and-more'
   *
   * @description this function call action to set wayFinderMethod to be 'step-and-more'
   * @param {Boolean} bool
   */
  setWayFinderMethodStepAndMore () {
    return (dispatch) => {
      dispatch(search.setWayFinderMethod({ payload: WAYFINDER_METHOD_STEP_AND_MORE }));
    };
  },
  resetState () {
    return (dispatch) => {
      dispatch(search.resetState());
    };
  },
};
