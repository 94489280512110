import React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Button } from "~/components";
import { useTheme, useTranslation, useRouter, useAction } from "~/hooks";
import { domainSelector } from "~/store/selector";
import searchAction from '~/store/actions/Search';

export default () => {
  const { theme } = useTheme();
  const { translate, currentLanguage } = useTranslation();
  const { push } = useRouter();
  const { projectId } = useSelector(
    createStructuredSelector({
      projectId: domainSelector.projectId,
    })
  );
  const actions = useAction(searchAction);

  const handleGoHome = () => {
    // clear origin & destination in store
    actions.resetState();
    push({
      regexPath: "/:lang/:projectId/explore",
      params: {
        projectId,
        currentLanguage,
      },
    });
  }
  return (
    <Wrapper className="home-button" theme={theme}>
      <Button
        className="is-secondary"
        size="small"
        leftIcon="Search"
        title={translate("search")}
        fullWidth={false}
        rounded={false}
        onClick={handleGoHome}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
