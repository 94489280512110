import React, { useState } from 'react';
import { bindingClassName } from '~/utils';
import { Icon } from '~/components';

export default () => {
  const [ showMobileNav, setShowMobileNav ] = useState(false);
  return (
    <>
      <header>
        <div className="container-fluid top-menu">
        <div className="row">
          <div className="col-2 col-lg-4" />
          <div className="col-8 col-lg-4 text-center">
            <a href="https://www.fashionisland.co.th">
              <img
                src="https://www.fashionisland.co.th/wp-content/themes/fashion-island/img/logo.png"
                className="img-fluid"
                id="logo"
              />
            </a>
          </div>
          <div className="col-2 col-lg-4 text-right position-static">
            {/* <div className="wrp-search-btn d-lg-none d-block">
              <button className="btn" id="toggle-form-mb" type="submit">
                <img src="https://www.fashionisland.co.th/wp-content/themes/fashion-island/img/icon-search.png" />
              </button>
            </div> */}
            {/* <span class="sp-line"></span> */}
            <a className="tel-link" href="tel:029475000">
              <img src="https://www.fashionisland.co.th/wp-content/themes/fashion-island/img/icon-contact-36x32_header.png" />
              0-2947-5000
            </a>
          </div>
        </div>
      </div>
          <div className="wrp-navbar ">
            <nav className="navbar navbar-expand-lg navbar-light ">
              <button className="navbar-toggler" type="button" onClick={() => setShowMobileNav(!showMobileNav)}>
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarMain">
                <ul id="menu-main-menu" className="nav justify-content-xl-center">
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-54"
                    className="c-red menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-54 nav-item"
                  >
                    <a
                      title="ABOUT US"
                      href="https://www.fashionisland.co.th/about-us/"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="dropdown-toggle nav-link menu-image-title-after"
                      id="menu-item-dropdown-54"
                      role="button"
                    >
                      ABOUT US
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="menu-item-dropdown-54"
                      role="menu"
                    >
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-52"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-52 nav-item"
                      >
                        <a
                          title="FASHIONISLAND"
                          href="https://www.fashionisland.co.th/about-us/fashionisland/"
                          className="dropdown-item menu-image-title-after"
                        >
                          FASHIONISLAND
                        </a>
                      </li>
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-51"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-51 nav-item"
                      >
                        <a
                          title="GRANDSTATION"
                          href="https://www.fashionisland.co.th/about-us/grandstation/"
                          className="dropdown-item menu-image-title-after"
                        >
                          GRANDSTATION
                        </a>
                      </li>
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-50"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50 nav-item"
                      >
                        <a
                          title="ISLAND HALL"
                          href="https://www.fashionisland.co.th/about-us/island-hall/"
                          className="dropdown-item menu-image-title-after"
                        >
                          ISLAND HALL
                        </a>
                      </li>
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-53"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-53 nav-item"
                      >
                        <a
                          title="OUR CORPORATE"
                          href="https://www.fashionisland.co.th/about-us/our-corporate/"
                          className="dropdown-item menu-image-title-after"
                        >
                          OUR CORPORATE
                        </a>
                      </li>
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-305"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-305 nav-item"
                      >
                        <a
                          title="NEWS"
                          href="https://www.fashionisland.co.th/about-us/news/"
                          className="dropdown-item menu-image-title-after"
                        >
                          NEWS
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-437"
                    className="c-orange menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-437 nav-item"
                  >
                    <a
                      title="PROMOTIONS & PRIVILEGES"
                      href="https://www.fashionisland.co.th/promotions-privileges/"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="dropdown-toggle nav-link menu-image-title-after"
                      id="menu-item-dropdown-437"
                      role="button"
                    >
                      PROMOTIONS &amp; PRIVILEGES
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="menu-item-dropdown-437"
                      role="menu"
                    >
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-442"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-442 nav-item"
                      >
                        <a
                          title="BRAND PROMOTIONS"
                          href="https://www.fashionisland.co.th/promotion/brand-promotion/"
                          className="dropdown-item menu-image-title-after"
                        >
                          BRAND PROMOTIONS
                        </a>
                      </li>
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-443"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-443 nav-item"
                      >
                        <a
                          title="CO - PROMOTIONS"
                          href="https://www.fashionisland.co.th/promotion/co-promotion/"
                          className="dropdown-item menu-image-title-after"
                        >
                          CO – PROMOTIONS
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-695"
                    className="c-blue menu-item menu-item-type-custom menu-item-object-custom menu-item-695 nav-item"
                  >
                    <a
                      title="EVENTS"
                      href="https://www.fashionisland.co.th/event/events/"
                      className="nav-link menu-image-title-after"
                    >
                      EVENTS
                    </a>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-303"
                    className="c-pink d-none menu-item menu-item-type-custom menu-item-object-custom menu-item-303 nav-item"
                  >
                    <a
                      title="DIRECTORY"
                      href="#"
                      className="nav-link menu-image-title-after"
                    >
                      DIRECTORY
                    </a>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-1061"
                    className="c-green menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-1061 nav-item"
                  >
                    <a
                      title="SERVICES"
                      href="https://www.fashionisland.co.th/customers/"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="dropdown-toggle nav-link menu-image-title-after"
                      id="menu-item-dropdown-1061"
                      role="button"
                    >
                      SERVICES
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="menu-item-dropdown-1061"
                      role="menu"
                    >
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-1062"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1062 nav-item"
                      >
                        <a
                          title="CUSTOMERS"
                          href="https://www.fashionisland.co.th/customers/"
                          className="dropdown-item menu-image-title-after"
                        >
                          CUSTOMERS
                        </a>
                      </li>
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-1063"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1063 nav-item"
                      >
                        <a
                          title="TENANTS"
                          href="https://www.fashionisland.co.th/tenants/"
                          className="dropdown-item menu-image-title-after"
                        >
                          TENANTS
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-507"
                    className="c-blue menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-507 nav-item"
                  >
                    <a
                      title="CONTACT"
                      href="https://www.fashionisland.co.th/contact/"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="dropdown-toggle nav-link menu-image-title-after"
                      id="menu-item-dropdown-507"
                      role="button"
                    >
                      CONTACT
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="menu-item-dropdown-507"
                      role="menu"
                    >
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-700"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-700 nav-item"
                      >
                        <a
                          title="CAREER"
                          href="https://www.fashionisland.co.th/career/"
                          className="dropdown-item menu-image-title-after"
                        >
                          CAREER
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item wrp-lang-btn">
                    <div className="wrp-search-btn">
                      <button className="btn" id="toggle-form" type="submit">
                        <img src="https://www.fashionisland.co.th/wp-content/themes/fashion-island/img/icon-search.png" />
                      </button>
                    </div>
                    <div className="lang-list">
                      <a
                        href="https://www.fashionisland.co.th/en/"
                        type="button"
                        title="Eng"
                        className="dropdown-item "
                      >
                        <img
                          src="https://www.fashionisland.co.th/wp-content/plugins/multisite-language-switcher/flags/us.png"
                          alt="en_US"
                        />
                      </a>
                      <a
                        href="https://www.fashionisland.co.th/"
                        type="button"
                        title="ไทย"
                        className="dropdown-item current"
                      >
                        <img
                          src="https://www.fashionisland.co.th/wp-content/plugins/multisite-language-switcher/flags/th.png"
                          alt="th"
                        />
                      </a>{" "}
                    </div>
                    <div className="dropdown" style={{ display: "none" }}>
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownLang"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <a href="https://www.fashionisland.co.th/">
                          <img
                            src="https://www.fashionisland.co.th/wp-content/plugins/multisite-language-switcher/flags/th.png"
                          />
                        </a>
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownLang"
                      >
                        <a
                          href="https://www.fashionisland.co.th/en/"
                          type="button"
                          title="Eng"
                          className="dropdown-item "
                        >
                          <img
                            src="https://www.fashionisland.co.th/wp-content/plugins/multisite-language-switcher/flags/us.png"
                            alt="en_US"
                          />
                        </a>
                        <a
                          href="https://www.fashionisland.co.th/"
                          type="button"
                          title="ไทย"
                          className="dropdown-item current"
                        >
                          <img
                            src="https://www.fashionisland.co.th/wp-content/plugins/multisite-language-switcher/flags/th.png"
                            alt="th"
                          />
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          {/* <div className="wrp-search-form">
            <form
              className="search-form"
              method="get"
              action="https://www.fashionisland.co.th"
            >
              <input type="text" name="s" required />
              <button className="btn" type="submit">
                <img src="https://www.fashionisland.co.th/wp-content/themes/fashion-island/img/icon-search.png" />
              </button>
            </form>
            <span className="wrp-bubble">
              <a
                className="item-bubble c-red "
                href="https://www.fashionisland.co.th/event/events/"
                target="_self"
              >
                อีเว้นท์
              </a>
              <a
                className="item-bubble c-orange"
                href="https://www.fashionisland.co.th/promotions-privileges/"
                target="_self"
              >
                โปรโมชั่น
              </a>
              <a
                className="item-bubble c-blue"
                href="https://www.fashionisland.co.th/about-us/island-hall"
                target="_self"
              >
                ฮอลล์
              </a>
              <a
                className="item-bubble c-green"
                href="https://www.fashionisland.co.th/services-for-customer"
                target="_self"
              >
                บริการ
              </a>
            </span>
          </div> */}
        </header>
        {/* Mobile Menu */}
        <ul className={bindingClassName('mobile-nav', { 'is-open': showMobileNav })}>
          <li className="wrp-close-btn">
            <button className="navbar-toggler" type="button" onClick={() => setShowMobileNav(false)}>
              <Icon name="Times" />
            </button>
          </li>
          <li className="wrp-lang-btn">
            <a
              href="https://www.fashionisland.co.th/en/"
              type="button"
              title="Eng"
              className="dropdown-item "
            >
              <img
                src="https://www.fashionisland.co.th/wp-content/plugins/multisite-language-switcher/flags/us.png"
                alt="en_US"
              />
            </a>
            <a
              href="https://www.fashionisland.co.th/"
              type="button"
              title="ไทย"
              className="dropdown-item current"
            >
              <img
                src="https://www.fashionisland.co.th/wp-content/plugins/multisite-language-switcher/flags/th.png"
                alt="th"
              />
            </a>{" "}
          </li>
          <li className="wrp-back-btn">
            <button className="btn nav-back-btn" type="button">
              <i className="fas fa-chevron-left" />
              กลับ
            </button>
          </li>
          <li
            itemScope="itemscope"
            itemType="https://www.schema.org/SiteNavigationElement"
            id="menu-item-320"
            className="c-pink menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-37 current_page_item active menu-item-320 nav-item"
          >
            <a
              title="HOME"
              href="https://www.fashionisland.co.th/"
              className="nav-link menu-image-title-after"
              aria-current="page"
            >
              HOME
            </a>
          </li>
          <li
            itemScope="itemscope"
            itemType="https://www.schema.org/SiteNavigationElement"
            id="menu-item-314"
            className="c-red menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-314 nav-item"
          >
            <a
              title="ABOUT US"
              href="#"
              className="nav-link menu-image-title-after"
              id="menu-item-dropdown-314"
            >
              ABOUT US
              <i className="fas fa-chevron-right" />{" "}
            </a>
            <div
              className="sub-menu"
              aria-labelledby="menu-item-dropdown-314"
              role="menu"
            >
              <a
                title="ABOUT US"
                href="https://www.fashionisland.co.th/about-us/"
                className="sub-link menu-image-title-after"
              >
                ABOUT US
              </a>
              <a
                title="FASHIONISLAND"
                href="https://www.fashionisland.co.th/about-us/fashionisland/"
                className="sub-link menu-image-title-after"
              >
                FASHIONISLAND
              </a>
              <a
                title="GRANDSTATION"
                href="https://www.fashionisland.co.th/about-us/grandstation/"
                className="sub-link menu-image-title-after"
              >
                GRANDSTATION
              </a>
              <a
                title="ISLAND HALL"
                href="https://www.fashionisland.co.th/about-us/island-hall/"
                className="sub-link menu-image-title-after"
              >
                ISLAND HALL
              </a>
              <a
                title="OUR CORPORATE"
                href="https://www.fashionisland.co.th/about-us/our-corporate/"
                className="sub-link menu-image-title-after"
              >
                OUR CORPORATE
              </a>
              <a
                title="NEWS"
                href="https://www.fashionisland.co.th/about-us/news/"
                className="sub-link menu-image-title-after"
              >
                NEWS
              </a>
            </div>
          </li>
          <li
            itemScope="itemscope"
            itemType="https://www.schema.org/SiteNavigationElement"
            id="menu-item-433"
            className="c-orange menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-433 nav-item"
          >
            <a
              title="PROMOTIONS & PRIVILEGES"
              href="#"
              className="nav-link menu-image-title-after"
              id="menu-item-dropdown-433"
            >
              PROMOTIONS &amp; PRIVILEGES
              <i className="fas fa-chevron-right" />{" "}
            </a>
            <div
              className="sub-menu"
              aria-labelledby="menu-item-dropdown-433"
              role="menu"
            >
              <a
                title="PROMOTIONS & PRIVILEGES"
                href="https://www.fashionisland.co.th/promotions-privileges/"
                className="sub-link menu-image-title-after"
              >
                PROMOTIONS &amp; PRIVILEGES
              </a>
              <a
                title="BRAND PROMOTIONS"
                href="https://www.fashionisland.co.th/promotion/brand-promotion/"
                className="sub-link menu-image-title-after"
              >
                BRAND PROMOTIONS
              </a>
              <a
                title="CO - PROMOTIONS"
                href="https://www.fashionisland.co.th/promotion/co-promotion/"
                className="sub-link menu-image-title-after"
              >
                CO – PROMOTIONS
              </a>
              <a
                title="THE ISLAND CARD"
                href="https://www.fashionisland.co.th/the-islad-card/"
                className="sub-link menu-image-title-after"
              >
                THE ISLAND CARD
              </a>
              <a
                title="TOURIST PRIVILEGES"
                href="#"
                className="sub-link menu-image-title-after"
              >
                TOURIST PRIVILEGES
              </a>
            </div>
          </li>
          <li
            itemScope="itemscope"
            itemType="https://www.schema.org/SiteNavigationElement"
            id="menu-item-696"
            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-696 nav-item"
          >
            <a
              title="EVENTS"
              href="https://www.fashionisland.co.th/event/events/"
              className="nav-link menu-image-title-after"
            >
              EVENTS
            </a>
          </li>
          <li
            itemScope="itemscope"
            itemType="https://www.schema.org/SiteNavigationElement"
            id="menu-item-436"
            className="c-blue d-none menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-436 nav-item"
          >
            <a
              title="EVENTS"
              href="#"
              className="nav-link menu-image-title-after"
              id="menu-item-dropdown-436"
            >
              EVENTS
              <i className="fas fa-chevron-right" />{" "}
            </a>
            <div
              className="sub-menu"
              aria-labelledby="menu-item-dropdown-436"
              role="menu"
            >
              <a
                title="EVENTS"
                href="https://www.fashionisland.co.th/event/events/"
                className="sub-link menu-image-title-after"
              >
                EVENTS
              </a>
              <a
                title="WHAT’S NEW"
                href="https://www.fashionisland.co.th/event/whats-new/"
                className="sub-link menu-image-title-after"
              >
                WHAT’S NEW
              </a>
            </div>
          </li>
          <li
            itemScope="itemscope"
            itemType="https://www.schema.org/SiteNavigationElement"
            id="menu-item-323"
            className="c-pink d-none menu-item menu-item-type-custom menu-item-object-custom menu-item-323 nav-item"
          >
            <a title="DIRECTORY" href="#" className="nav-link menu-image-title-after">
              DIRECTORY
            </a>
          </li>
          <li
            itemScope="itemscope"
            itemType="https://www.schema.org/SiteNavigationElement"
            id="menu-item-508"
            className="c-green menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-508 nav-item"
          >
            <a
              title="SERVICES"
              href="#"
              className="nav-link menu-image-title-after"
              id="menu-item-dropdown-508"
            >
              SERVICES
              <i className="fas fa-chevron-right" />
            </a>
            <div
              className="sub-menu"
              aria-labelledby="menu-item-dropdown-508"
              role="menu"
            >
              <a
                title="CUSTOMERS"
                href="https://www.fashionisland.co.th/customers/"
                className="sub-link menu-image-title-after"
              >
                CUSTOMERS
              </a>
              <a
                title="TENANTS"
                href="https://www.fashionisland.co.th/tenants/"
                className="sub-link menu-image-title-after"
              >
                TENANTS
              </a>
            </div>
          </li>
          <li
            itemScope="itemscope"
            itemType="https://www.schema.org/SiteNavigationElement"
            id="menu-item-510"
            className="c-blue d-none menu-item menu-item-type-post_type menu-item-object-page menu-item-510 nav-item"
          >
            <a
              title="CONTACT"
              href="https://www.fashionisland.co.th/contact/"
              className="nav-link menu-image-title-after"
            >
              CONTACT
            </a>
          </li>
          <li
            itemScope="itemscope"
            itemType="https://www.schema.org/SiteNavigationElement"
            id="menu-item-699"
            className="c-blue menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-699 nav-item"
          >
            <a
              title="CONTACT"
              href="#"
              className="nav-link menu-image-title-after"
              id="menu-item-dropdown-699"
            >
              CONTACT
              <i className="fas fa-chevron-right" />
            </a>
            <div
              className="sub-menu"
              aria-labelledby="menu-item-dropdown-699"
              role="menu"
            >
              <a
                title="CONTACT"
                href="https://www.fashionisland.co.th/contact/"
                className="sub-link menu-image-title-after"
              >
                CONTACT
              </a>
              <a
                title="CAREER"
                href="https://www.fashionisland.co.th/career/"
                className="sub-link menu-image-title-after"
              >
                CAREER
              </a>
            </div>
          </li>
        </ul>
    </>
  )
}