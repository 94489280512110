import { createSelector } from 'reselect';
import sortBy from 'lodash.sortby';

import helper from '../helper';
import { toSelectOptions } from '~/utils';

/**
 * Entire visibility
 * @description visibility filter from redux store
 */
const entireVisibilityFilter = helper.getEntireVisibilityFilter;

/**
 * Entire category
 * @description entire category from redux store
 */
const entire = helper.getEntireBuilding;

/**
 * Buildings
 * @description Buildings which convert to array
 */
const buildings = createSelector(
  [entire],
  entireBuilding => sortBy(Object.entries(entireBuilding).reduce((result, building) => ([ ...result, { id: building[0], ...building[1] }]), []), building => building.order),
);

/**
 * Get building by id
 * @description Get a single building by provide id
 * @param {String|Number} id
 */
const getBuildingId = createSelector(
  [helper.getEntireBuilding],
  entireBuilding => id => entireBuilding[id],
);

/**
 * Get building options
 * @description Get building options
 * @param {String|Number} id
 */
const buildingOptions = createSelector(
  [buildings],
  buildingArray => translate => (
    toSelectOptions(buildingArray, building => translate(building.name), building => building.id)
  ),
);

const activeBuilding = createSelector(
  [ buildingOptions, entireVisibilityFilter ],
  (buildingOptions, visibilityFilter) => translation => {
    return buildingOptions(translation).find(buildingOption => buildingOption.value === visibilityFilter.building.value)
  }
);

const hasManyBuildings = createSelector(
  [buildings],
  buildings => buildings.length > 1,
);

export default {
  entire,
  buildings,
  getBuildingId,
  activeBuilding,
  buildingOptions,
  hasManyBuildings,
};
