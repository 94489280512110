import { createModule } from 'redux-modux';

import { WAYFINDER_METHOD_OVERVIEW } from '~/app/variables';
import logger from '~/lib/logger';

const initialState = {
  originSearchText: '',
  originShop: {},
  destinationSearchText: '',
  destinationShop: {},
  isAbleToGetDirection: undefined,
  wayFinderMethod: WAYFINDER_METHOD_OVERVIEW,
};

/**
 * Handler action
 */
const handlers = {
  /**
   * Set origin search text
   *
   * @description set search text value of origin unit
   * @param  {{}} state
   * @param  {{ payload: string }} action
   */
  setOriginSearchText (state, action) {
    return {
      ...state,
      originSearchText: action.payload,
    };
  },
  /**
   * Set origin shop
   *
   * @description set shop detail of origin
   * @param  {{}} state
   * @param  {{ payload: {} }} action
   */
  setOriginShop (state, action) {
    return {
      ...state,
      originShop: action.payload,
    };
  },
  /**
   * Set origin search
   *
   * @param {{}} state
   * @param {{ payload: { originSearchText: string, originShop: {} }}} action
   */
  setOrigin (state, action) {
    const { originSearchText, originShop } = action.payload;
    return {
      ...state,
      originSearchText,
      originShop,
    };
  },
  /**
   * Set destination search text
   *
   * @description set search text value of destination unit
   * @param  {{}} state
   * @param  {{ payload: string }} action
   */
  setDestinationSearchText (state, action) {
    return {
      ...state,
      destinationSearchText: action.payload,
    };
  },
  /**
   * Set destination shop
   *
   * @description set shop detail of origin
   * @param {{}} state
   * @param {{ payload: {} }} action
   */
  setDestinationShop (state, action) {
    return {
      ...state,
      destinationShop: action.payload,
    };
  },
  /**
   * Set destination search
   *
   * @param {{}} state
   * @param {{ payload: {} }} action
   */
  setDestination (state, action) {
    const { destinationSearchText, destinationShop } = action.payload;
    return {
      ...state,
      destinationSearchText,
      destinationShop,
    };
  },
  /**
   * Set is able to get direction
   *
   * @description this function tell the application should disable get direction feature or not.
   * @param {{}} state
   * @param {{ payload: Boolean }} action
   */
  setIsAbleToGetDirection (state, action) {
    return {
      ...state,
      isAbleToGetDirection: action.payload,
    };
  },

  /**
   * Set wayFinderMethod to be 'overview' or 'step-and-more'
   *
   * @deprecated Marked as deprecated since 0.1.5. Wil remove in 0.2.0 version.
   * @description this function set the application wayFinderMethod to 'overview' or 'step-and-more'
   * @param {{}} state
   */
  setWayFinderMethod (state, action) {
    logger.warn('SearchModule.setWayFinderMethod is marked as deprecated since 0.1.5 version. It will remove in 0.2.0 version.');

    return {
      ...state,
      wayFinderMethod: action.payload,
      directionStrategy: action.payload,
    };
  },

  /**
   * Set direction strategy to be 'overview' or 'step-and-more'
   *
   * @description this function set the application direction strategy to 'overview' or 'step-and-more'
   * @param {object} state
   * @param {{ payload: object }} action
   */
  setDirectionStrategy (state, action) {
    return {
      ...state,
      wayFinderMethod: action.payload,
      directionStrategy: action.payload,
    };
  },

  resetState (state, action) {
    return initialState
  }
};

export default createModule({
  moduleName: 'search',
  initialState,
  handlers,
});
