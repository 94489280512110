import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { domainSelector } from '~/store/selector';

import TerminalAsokHeader from './terminal21/Asok';
import TerminalPattayaHeader from './terminal21/Pattaya';
import TerminalKoratHeader from './terminal21/Korat';
import TerminalRama3Header from './terminal21/Rama3';
import ThePromenadeHeader from './thepromenade';
import FashionislandHeader from './fashionisland';

/**
 * Customise header
 *
 * @return {JSX.Element}
 */
function Header () {
  const { projectId } = useSelector(
    createStructuredSelector({
      projectId: domainSelector.projectId,
    })
  );

  let Header = <div />;

  switch (projectId) {
    default:
    case 'terminal21-asok':
      Header = <TerminalAsokHeader />;
      break;
    case 'terminal21-korat':
      Header = <TerminalKoratHeader />;
      break;
    case 'terminal21-rama3':
      Header = <TerminalRama3Header />;
      break;
    case 'terminal21-pattaya':
      Header = <TerminalPattayaHeader />;
      break;
    case 'thepromenade':
      Header = <ThePromenadeHeader />;
      break;
    case 'fashionisland':
      Header = <FashionislandHeader />;
      break;
  }

  return <>{Header}</>;
}

export default Header;
