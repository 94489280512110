import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Select from 'react-select';

const Dropdown = ({ className, options, onChange, defaultValue, value }) => {
  console.log('Dropdown', { className, options, onChange, defaultValue, value });
  return (
    <Wrapper>
      <Select
        className={className}
        defaultValue={defaultValue}
        options={options}
        onChange={onChange}
        value={value}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`

`;

Dropdown.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType(PropTypes.string, PropTypes.number).isRequired,
  }),
};

Dropdown.defaultProps = {
  className: '',
  defaultValue: '',
  options: [],
  onChange: () => {},
  value: '',
};

export default Dropdown;
